// import React, { useState } from "react";
// import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
// import InfiniteLogo from "../components/InfiniteLogo"; // Assuming this is your logo carousel component

// import logo from '../Assets/logo.png';

// export default function ChatbotPage() {
//   const [messages, setMessages] = useState([]);
//   const [inputText, setInputText] = useState('');
//   const [searched, setSearched] = useState(false);
//   const [showResponse, setShowResponse] = useState(false);

//   const handleSendMessage = async (message) => {
//     // Add user's message to the chat
//     setMessages(prevMessages => [
//       ...prevMessages,
//       { role: 'user', text: message }
//     ]);

//     // Clear input field
//     setInputText('');

//     // If this is the first search, set searched to true
//     if (!searched) {
//       setSearched(true);
//     }

//     // If this is the first question, show the response component
//     if (!showResponse) {
//       setShowResponse(true);
//     }

//     // Simulate typing indicator
//     setTimeout(async () => {
//       // Simulate delay before receiving chatbot response
//       await simulateTyping();

//       // Simulate chatbot response (replace with actual API call)
//       const response = await getChatbotResponse(message);
//       setMessages(prevMessages => [
//         ...prevMessages,
//         { role: 'assistant', text: response }
//       ]);
//     }, 1000);
//   };

//   const simulateTyping = () => {
//     return new Promise(resolve => {
//       setTimeout(resolve, 1500); // Simulate typing for 1.5 seconds
//     });
//   };

//   const getChatbotResponse = async (inputText) => {
//     // Simulate backend response (replace with actual API call)
//     return "This is a dummy response from the PypiRecom chatbot.";
//   };

//   const handleInputChange = (e) => {
//     setInputText(e.target.value);
//   };

//   const handleFAQClick = async (faq) => {
//     await handleSendMessage(faq);
//   };

//   const isSearchDisabled = inputText.trim() === '';

//   return (
//     <div>
//       <Navbar />

//       <div className="container mt-5">
//         <div className="row justify-content-center">
//           <div className="col-md-8 text-center">
//             <img src={logo} alt="Logo" style={{ width: '300px' }} />
//             <h3 className="mt-4">PypiRecom GPT</h3>
//           </div>
//         </div>

//         <div className="row justify-content-center mt-4">
//           <div className="col-md-8">
//             {messages.map((message, index) => (
//               <div key={index} className={`message-${message.role} mt-3 p-3 border rounded`}>
//                 <p className="mb-0">{message.role === 'user' ? 'You: ' : 'PypiRecom: '}{message.text}</p>
//               </div>
//             ))}
//           </div>
//         </div>

//         {!searched && (
//           <div className="row justify-content-center mt-4">
//             <div className="col-md-8">
//               {/* Chatbot initial suggestions */}
//               <div className="row justify-content-center">
//                 <div className="col-md-5">
//                   <div className="card mb-3">
//                     <div className="card-body">
//                       <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Show me all the dependencies")}>Show me all the dependencies</button>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-5">
//                   <div className="card mb-3">
//                     <div className="card-body">
//                       <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Give all the packages")}>Give all the packages</button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row justify-content-center">
//                 <div className="col-md-5">
//                   <div className="card mb-3">
//                     <div className="card-body">
//                       <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Give me all the license")}>Give me all the license</button>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-5">
//                   <div className="card mb-3">
//                     <div className="card-body">
//                       <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Show me the development status")}>Show me the development status</button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}

//         <div className="row justify-content-center mt-4">
//           <div className="col-md-7">
//             <div className="input-group">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Type your message here..."
//                 value={inputText}
//                 onChange={handleInputChange}
//               />
//               <button className="btn btn-primary" onClick={() => handleSendMessage(inputText)} disabled={isSearchDisabled}>
//                 <i className="fas fa-arrow-right"></i> Send
//               </button>
//             </div>
//           </div>
//         </div>

//         <div className="row justify-content-center mt-4">
//           <div className="col-md-12">
//             <InfiniteLogo /> {/* Include your logo carousel component here */}
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// }
import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import InfiniteLogo from "../components/InfiniteLogo"; // Assuming this is your logo carousel component

import logo from '../Assets/logo.png';

export default function ChatbotPage() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [searched, setSearched] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const handleSendMessage = async (message) => {
    // Add user's message to the chat
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'user', text: message }
    ]);

    // Clear input field
    setInputText('');

    // If this is the first search, set searched to true
    if (!searched) {
      setSearched(true);
    }

    // If this is the first question, show the response component
    if (!showResponse) {
      setShowResponse(true);
    }

    // Simulate typing indicator
    setTimeout(async () => {
      // Simulate delay before receiving chatbot response
      await simulateTyping();

      // Simulate chatbot response (replace with actual API call)
      const response = await getChatbotResponse(message);
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', text: response }
      ]);
    }, 1000);
  };

  const simulateTyping = () => {
    return new Promise(resolve => {
      setTimeout(resolve, 1500); // Simulate typing for 1.5 seconds
    });
  };

  const getChatbotResponse = async (inputText) => {
    try {
      const response = await fetch('http://localhost:8000/rag-agent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: inputText })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.output;
    } catch (error) {
      console.error('Error:', error);
      return 'Sorry, something went wrong. Please try again later.';
    }
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleFAQClick = async (faq) => {
    await handleSendMessage(faq);
  };

  const isSearchDisabled = inputText.trim() === '';

  return (
    <div>
      <Navbar />

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <img src={logo} alt="Logo" style={{ width: '300px' }} />
            <h3 className="mt-4">PypiRecom Chatbot</h3>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div className="col-md-8">
            {messages.map((message, index) => (
              <div key={index} className={`message-${message.role} mt-3 p-3 border rounded`}>
                <p className="mb-0">{message.role === 'user' ? 'You: ' : 'PypiRecom: '}{message.text}</p>
              </div>
            ))}
          </div>
        </div>

        {!searched && (
          <div className="row justify-content-center mt-4">
            <div className="col-md-8">
              {/* Chatbot initial suggestions */}
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card mb-3">
                    <div className="card-body">
                      <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Show me all the dependencies")}>Show me all the dependencies</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card mb-3">
                    <div className="card-body">
                      <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Give all the packages")}>Give all the packages</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card mb-3">
                    <div className="card-body">
                      <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Give me all the license")}>Give me all the license</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card mb-3">
                    <div className="card-body">
                      <button type="button" className="btn btn-outline-primary w-100" onClick={() => handleFAQClick("Show me the development status")}>Show me the development status</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row justify-content-center mt-4">
          <div className="col-md-7">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type your message here..."
                value={inputText}
                onChange={handleInputChange}
              />
              <button className="btn btn-primary" onClick={() => handleSendMessage(inputText)} disabled={isSearchDisabled}>
                <i className="fas fa-arrow-right"></i> Search
              </button>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div className="col-md-12">
            <InfiniteLogo /> {/* Include your logo carousel component here */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
